.guide-btn-group-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.4rem;

  .guide-btn-group-item {
    width: fit-content;
    height: fit-content;
    display: flex;
    column-gap: 1rem;
    row-gap: 0.4rem;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;

    .guide-btn-group-item__name {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .guide-btn-group-item__count {
      font-weight: bold;
      font-size: 20px;
    }
  }

  @media only screen and (max-width: 1200px) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(183px, 1fr));
    .guide-btn-group-item {
      width: 100%;
    }
  }

  @media only screen and (max-width: 480px) {
    display: grid;
    grid-template-columns: 1fr;
    .guide-btn-group-item {
      width: 100%;
    }
  }
}
