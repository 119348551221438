.ant-input-focused,
.ant-input:focus {
  box-shadow: none;
}

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
  .ant-btn {
    border-radius: 0;

    &:last-of-type {
      border-radius: 0 2px 2px 0;
    }
  }
}

.ant-select {
  max-width: 100%;
  white-space: nowrap;
  // overflow: hidden; // remove because form bottom space
  text-overflow: ellipsis;
}

nz-upload {
  .ant-upload.ant-upload-select {
    display: flex;
    justify-content: end;
    margin-bottom: 16px;
  }
  .ant-upload-list-item {
    background-color: #f5f5f5;
    padding: 0.6rem;
    margin-top: 0;
    height: 40px;
  }
  .ant-upload-list-item-card-actions-btn {
    opacity: 1;
  }
}

//** Disabled **//
.ant-input-disabled:hover,
.ant-input[disabled]:hover,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #d9d9d9;
}

.ant-input-group {
  .ant-input-disabled:hover,
  .ant-input[disabled]:hover {
    border-right-width: 1px !important;
  }
}
nz-input-group:has(.ant-input-disabled),
nz-input-group:has(.ant-input[disabled]) {
  background-color: #f5f5f5;
}

.ant-picker.ant-picker-disabled,
.ant-picker.ant-picker-disabled input,
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-checkbox-disabled + span,
.ant-input-number-disabled input,
.ant-input-disabled,
.ant-input[disabled] {
  color: rgba(0, 0, 0, 0.87);
  cursor: default !important;
}
.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.87);
  cursor: default;
}
.ant-input-affix-wrapper-disabled:has(.ant-input-disabled) {
  cursor: default;
}

.ant-select-disabled {
  nz-select-arrow {
    display: none;
  }
}

.ant-switch-disabled,
.ant-switch-loading,
.ant-switch-disabled *,
.ant-switch-loading * {
  cursor: default;
  opacity: 0.6;
}
.ant-btn[disabled],
.ant-checkbox-disabled .ant-checkbox-inner,
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: default;
}

//** END Disabled **//
