@import '~@mdi/font/css/materialdesignicons.css';

@white-color: #fff;
@primary-color: purple;
@success-color: #1abc9c;
@warn-color: #f7b84b;
@error-color: #f1556c;
@border-color: #d9d9d9;

//ScrollBar
@scroll-width: 4px;
@scroll-border-radius: 1rem;
@sroll-track-color: #d4d4d4;
@sroll-thumb: #aaaaaa;
@scroll-thumb-hover: #969696;

/* You can add global styles to this file, and also import other style files */
app-root {
  height: 100vh;
  display: block !important;
}

app-layout-container {
  height: 100%;
}

.app-layout {
  height: 100%;
  overflow: auto;
}

.ant-layout {
  min-height: unset !important;
  height: 100%;
}

.ant-layout-content {
  min-height: unset !important;
  height: auto;
}

.ant-card-round {
  border-radius: 15px;
}

.date-col {
  width: 100px;
}

.contact-col {
  width: 120px;
}

.status-col {
  text-align: center;
  width: 100px;
}

.status-col--guides {
  text-align: center !important;
  width: 140px;
}

.options-col {
  text-align: center;
  width: 70px;

  span {
    margin: 5px;
    cursor: pointer;
  }
}

.ant-modal-body {
  max-height: calc(100vh - 248px);
  overflow: overlay;
}

nz-form-item.ant-form-item {
  margin: 0 0 10px !important;
}

label.required::after {
  content: ' *';
  color: @error-color;
}

.page-title {
  margin-bottom: 24px;
}

.status-badge {
  font-size: 0.8rem;
  padding: 0 6px;
  height: 20px;
  line-height: normal;
  border-radius: 4px;
  width: fit-content;

  white-space: nowrap;
}

.content {
  min-height: 100%;
}

.no-results-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 4px;
  padding: 1rem 0.4rem;
  background-color: #f9f9f9 !important;
}

.inner-content {
  padding: 24px;
  background: @white-color;
  min-height: 100%;
}

.inner-content-page-title {
  padding: 24px;
  background: #fff;
  // min-height: calc(100% - 74px);
}

.ant-page-header {
  padding: 5px 20px !important;
}

.ant-page-header.title {
  padding: 0 0 15px 0 !important;
}

.search {
  width: 200px;

  .anticon-search,
  .anticon-close {
    color: @border-color;
  }

  .anticon-close:hover {
    cursor: pointer;
    color: black;
  }
}

nz-spin.base-spin {
  width: min-content;
  margin: 25% auto;
}

.ant-picker {
  width: 100% !important;
}

.ant-modal-body nz-spin {
  // width: min-content;
  margin: auto;
}

.avatar-picker-wrapper {
  width: min-content;
  display: flex;
  flex-direction: row-reverse;
  align-content: flex-end;
  align-items: flex-start;
  position: relative;
  margin: auto;

  .ant-btn-circle {
    position: absolute;
    right: -5px;
    z-index: 9;
  }
}

.ant-avatar.avatar-picker {
  width: 100px;
  height: 100px;
  line-height: 100px;
}

.ant-page-header-content,
.ant-page-header.has-breadcrumb {
  padding-top: 5px !important;
}

.error-color {
  color: @error-color;
}

.input-error {
  border-color: @error-color;
}

nz-select.input-error nz-select-top-control {
  border-color: @error-color !important;
}

.user-avatar-col {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
}

.filter {
  background-color: @white-color;
  padding: 10px 14px;
  margin: 0 0 24px 0 !important;

  .ant-col {
    display: grid;
  }

  .ant-col button {
    margin: auto 0 0 0;
  }
}

.filter-info {
  background-color: @white-color;
  padding: 10px 0;
  margin: 0 0 24px 0 !important;

  .ant-col {
    display: grid;
  }
}

.filter-modal {
  background-color: @white-color;
  margin: 0 0 24px 0 !important;
}

.btn-icon {
  padding: 4px 6px !important;
}

.tox-tinymce {
  border-radius: unset !important;
}

.modal-error .ant-btn[disabled] {
  display: none;
}

.ngx-charts-outer {
  margin: auto;
}

.chart-legend .legend-labels {
  white-space: normal !important;
}

.stats-content {
  display: flex;
  flex-direction: column;

  small {
    font-size: 10px;
    margin-top: -5px;
    color: @border-color;
  }
}

.footer-auth {
  margin-bottom: -15px;
  margin-top: 15px;
  text-align: center;
  font-size: 12px;
}

.ant-tag {
  margin: 0;
}

.ant-table-expanded-row-fixed {
  width: 100% !important;
}

@media only screen and (max-width: 576px) {
  .login-card {
    min-width: unset !important;
    margin: 10px;
  }

  .error-card {
    width: auto !important;
    margin: 10px !important;
  }

  .header-trigger .username {
    display: none;
  }

  .ant-space {
    display: block !important;

    .ant-space-item {
      margin-bottom: 5px;
      margin-right: 0 !important;
    }
  }

  .search {
    width: auto !important;
  }

  .ant-descriptions-item-container {
    display: flex;
    flex-direction: column;
  }

  // .row {
  //   display: flex;
  //   flex-direction: column !important;
  //   align-items: flex-start !important;

  //   .col + .col {
  //     margin-left: 0 !important;
  //     margin-top: 20px;
  //   }

  //   .status {
  //     width: 100% !important;
  //     max-width: 100% !important;
  //   }
  // }

  .registration {
    margin-left: 0 !important;
    margin-top: 15px;

    .row {
      flex-direction: row !important;

      .col + .col {
        margin-left: 0 !important;
        margin-top: 0;
      }
    }

    .row-calendar {
      flex-direction: column !important;
    }
  }

  .company {
    .col + .col {
      margin-left: 0 !important;
      margin-top: 0;
    }
  }

  .btn-add-config {
    padding: 4px 10px !important;
  }

  .btn-text {
    display: none !important;
  }
}

.color-primary {
  color: #1890ff !important;
}

.ant-card-head:has(.hide-tabs) {
  display: none;
}
.ant-tabs.hide-tabs {
  nz-tabs-nav {
    display: none !important;
  }
}

.full-width-tabs {
  .ant-tabs-nav-list {
    width: 100%;
  }

  .ant-tabs-tab {
    flex: 1;
    text-align: center;
    justify-content: center;
    margin: 0 !important;
  }

  &.send-email {
    .ant-tabs-tab:nth-of-type(2) {
      flex: 2;
    }
  }
}

nz-card {
  .card-header-extra {
    display: flex;
    justify-content: end;
  }
}

nz-table {
  background-color: white;

  nz-pagination {
    margin-bottom: 5px !important;
    margin-right: 20px !important;

    nz-select {
      display: block !important;
    }
  }
  .ant-table {
    overflow-x: auto;
  }
  .table-row {
    cursor: pointer;
  }
  .ant-table-cell {
    .anticon {
      cursor: pointer;
    }
  }
}

nz-card {
  .ant-page-header {
    padding: 0 !important;
  }

  .ant-card-head {
    .ant-tabs-bottom > .ant-tabs-nav::before,
    .ant-tabs-bottom > div > .ant-tabs-nav::before,
    .ant-tabs-top > .ant-tabs-nav::before,
    .ant-tabs-top > div > .ant-tabs-nav::before {
      border: none;
    }
  }

  .ant-card-body:has(nz-table) {
    nz-table {
      nz-pagination {
        margin-bottom: 0 !important;
      }
    }
  }
}

.ant-divider-horizontal {
  margin: 12px 0 !important;
}

.hidden {
  display: none;
}

.ant-steps-navigation {
  padding: 0;

  .ant-steps-item {
    padding-top: 12px;

    &::after {
      margin-top: -6px;
      margin-left: -16px;
    }
  }
}

.swal2-title {
  font-size: 1.6rem !important;
}
.swal2-html-container__list {
  text-align: left;
  margin: 0;
  font-size: 1rem;
}
.swal2-html-container__list__title {
  font-weight: 600;
  font-size: 1rem;
}
.swal2-html-container__list__list {
  list-style-type: circle !important;
  margin-bottom: 10px;
  padding-left: 30px !important;
  max-height: 200px;
  overflow: auto;
  // background-color: #f2f2f2;
}
