.ant-table-filter-trigger {
  margin: -6px 0 -3px 0px;

  &:hover {
    background: inherit;
    color: inherit;
  }
}

.search-box {
  padding: 8px;

  nz-select {
    width: 100%;
  }

  input {
    width: 188px;
    margin-bottom: 8px;
    display: block;
  }

  .ant-picker {
    width: 100%;
    display: inline-flex;

    input {
      width: unset;
      margin: 0;
      text-align: center;
    }
  }

  .search-box-actions {
    margin-top: 4px;
    display: flex;
    gap: 4px;

    button {
      width: unset;
      margin: 0;
      flex: 1;
    }
  }

  button {
    width: 90px;
  }
}
