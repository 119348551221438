::-webkit-scrollbar {
  width: @scroll-width;
  height: @scroll-width;
}
::-webkit-scrollbar-track {
  background: @sroll-track-color;
}
::-webkit-scrollbar-thumb {
  background: @sroll-thumb;
  border-radius: @scroll-border-radius;
}
::-webkit-scrollbar-thumb:hover {
  background: @scroll-thumb-hover;
}
